@import 'variables';

body {
  @include fontDefault();

  margin: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

@import 'base';
@import 'home';
