#top {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  #flag {
    position: relative;
    width: 40vmin;

    @media (max-height: 500px) {
      margin: 6em 0;
      width: 33.333%;
    }

    @media (max-width: 768px) {
      width: 70%;
      margin: 6em 0;
    }

    svg > g {
      transform-origin: center;
      transform: scale(0.9);
    }

    #stamp {
      position: absolute;
      top: -2vmin;
      left: -4vmin;
      width: 15vmin;
      animation: rotating 5s linear infinite;

      @media (max-width: 768px) {
        width: 33.3333%;
      }
    }
  }

  #social-media {
    position: absolute;
    bottom: 3em;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      bottom: 2em;
    }

    #social-media-container {
      display: flex;
      justify-content: center;

      a:nth-of-type(1) {
        margin-right: 1em;
      }

      svg {
        width: 1.75em;

        path {
          transition: fill 0.3s;
        }

        &:hover {
          path {
            fill: #e94781;
          }
        }
      }
    }

    #social-media-text {
      color: #fff;
      margin-top: 1rem;
      font-size: 1.1em;
      text-transform: uppercase;
    }
  }
}

#slider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 2em;

  @media (max-width: 768px) {
    padding: 1em;
  }

  .slider-bar {
    width: 100%;
    height: 100%;

    .slider-single {
      opacity: 0;
      transition: opacity 1s;
      height: 100%;
      background-position: center;
      background-size: cover;

      &.current-slide {
        opacity: 1;
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
