$contentWidth: 60rem;
$contentWidthWide: 120rem;
$contentWidthText: 40rem;
$spacing: 3rem;
$wrapperSpacing: 3rem;

@font-face {
  font-family: 'GT America Mono';
  src: url('./../../media/GT-America-Mono-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@mixin fontDefault() {
  font-family: 'GT America Mono', sans-serif;
}
